import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
// Adding images from static folder to keep stable URL
const logos = () => {
    const data = useStaticQuery(graphql`
        query LogosSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Logos" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <StaticImage
                className='home-fixed-top'
                src='../resources/images/homepage/hero_lens.svg'
                placeholder='blurred'
                alt='Hero Lenses'
            />
            <section className='pt-5 mt-5 pb-5'>
                <div className='container-1'>
                    <div className='row mt-3'>
                        <div className='col-md-7 pt-0'>
                            <p className='title breadcrumb-text pt-4'>
                                <a href='#' className='title_text'>
                                    PRESS
                                </a>{' '}
                                /<span className='title_text font-weight-bold'>LOGOS</span>
                            </p>
                            <h1>Lenses.io Logos</h1>
                            <p className='hero-description-dark' style={{ paddingRight: '100px' }}>
                                Lenses & Lenses.io are registered trademarks.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id='summary' style={{ padding: '50px 0px 80px' }}>
                <div className='container-1'>
                    <div className='row'>
                        <div className='col-md-6 text-center'>
                            <h2 style={{ fontSize: '20px' }}>Lenses.io Logos</h2>
                        </div>
                        <div className='col-md-6 text-center'>
                            <h2 style={{ fontSize: '20px' }}>Lenses.io Icons</h2>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 text-center'>
                            <a
                                href='/images/new-logos/lenses-logo-new.png'
                                download={'lenses-logo-new.png'}
                                className='mx-auto'>
                                <img
                                    className='mx-auto'
                                    src={'/images/new-logos/lenses-logo-new.png'}
                                    alt='Lenses.io logo vertical'
                                    style={{ height: 'auto', width: '245px' }}
                                />
                            </a>
                            <hr />
                            <a
                                href='/images/new-logos/lenses-logo-new.png'
                                download={'lenses-logo-new.png'}
                                className='mx-auto'>
                                <img
                                    src={'/images/new-logos/lenses-logo-new.png'}
                                    className='mx-auto'
                                    alt='Lenses.io logo horizontal'
                                    style={{ height: 'auto', width: '345px' }}
                                />
                            </a>
                        </div>
                        <div className='col-md-6 text-center'>
                            <div className='row mx-auto'>
                                <a
                                    href='/images/new-logos/avatar.png'
                                    download={'avatar.png'}
                                    className='ml-auto'>
                                    <img
                                        className='ml-auto'
                                        src={'/images/new-logos/avatar.png'}
                                        alt='Lenses.io icon'
                                        style={{ height: '100px', width: '90px' }}
                                    />
                                </a>
                                <a
                                    href='/images/new-logos/avatar-circle.png'
                                    download={'avatar-circle.png'}
                                    className='mx-auto'>
                                    <img
                                        className='mx-auto'
                                        src={'/images/new-logos/avatar-circle.png'}
                                        alt='Lenses.io icon-b'
                                        style={{ height: '100px', width: '100px' }}
                                    />
                                </a>
                                {/*<img*/}
                                {/*  className="mr-auto"*/}
                                {/*  src={'/images/new-logos/avatar-circle.png'}*/}
                                {/*  alt="Lenses.io icon-c"*/}
                                {/*  style={{ height: '100px', width: '100px' }}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*  <div className="col-md-6 text-center">*/}
                    {/*    <img*/}
                    {/*      src={'/images/logos/lensesio-logo-dark.png'}*/}
                    {/*      className="mx-auto"*/}
                    {/*      alt="Lenses.io logo dark"*/}
                    {/*      style={{ height: '200px', width: '245px' }}*/}
                    {/*    />*/}
                    {/*    <hr />*/}
                    {/*    <img*/}
                    {/*      src={'/images/logos/lensesio-logo-dark-left.png'}*/}
                    {/*      className="mx-auto"*/}
                    {/*      alt="Lenses.io logo dark left"*/}
                    {/*      style={{ height: '100px', width: '345px' }}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*  <div className="col-md-6 text-center">*/}
                    {/*    <img*/}
                    {/*      className="mx-auto"*/}
                    {/*      src={'/images/logos/lensesio-logo-dark-icon.png'}*/}
                    {/*      alt="Lenses.io icon dark"*/}
                    {/*      style={{ height: '100px', width: '80px' }}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div
                        className='row'
                        style={{ marginTop: '50px', background: '#ccc', padding: '20px' }}>
                        <div className='col-md-6 text-center'>
                            <a
                                href='/images/logos/lensesio-logo-light.png'
                                download={'lensesio-logo-light.png'}>
                                <img
                                    className='mx-auto'
                                    src={'/images/logos/lensesio-logo-light.png'}
                                    alt='Lenses.io logo light'
                                    style={{ height: '200px', width: '245px' }}
                                />
                            </a>
                            <hr />
                            <a
                                href='/images/logos/lensesio-logo-light-left.png'
                                download={'lensesio-logo-light-left.png'}>
                                <img
                                    className='mx-auto'
                                    src={'/images/logos/lensesio-logo-light-left.png'}
                                    alt='Lenses.io logo lensesio-logo-light-left'
                                    style={{ height: '100px', width: '345px' }}
                                />
                            </a>
                        </div>
                        <div className='col-md-6 text-center'>
                            <div className='row'>
                                <a
                                    href='/images/logos/lensesio-logo-light-icon.png'
                                    download={'lensesio-logo-light-icon.png'}>
                                    <img
                                        className=''
                                        src={'/images/logos/lensesio-logo-light-icon.png'}
                                        alt='Lenses.io icon light'
                                        style={{
                                            marginLeft: '125px',
                                            height: '100px',
                                            width: '80px',
                                        }}
                                    />
                                </a>

                                {/*<img*/}
                                {/*  className="mx-auto"*/}
                                {/*  src={'/images/logos/lensesio-logo-icon-d.png'}*/}
                                {/*  alt="Lenses.io icon-d"*/}
                                {/*  style={{ height: '100px', width: '100px' }}*/}
                                {/*/>*/}
                                {/*<img*/}
                                {/*  className="mr-auto"*/}
                                {/*  src={'/images/logos/lensesio-logo-icon-e.png'}*/}
                                {/*  alt="Lenses.io icon-e"*/}
                                {/*  style={{ height: '100px', width: '100px' }}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default logos
